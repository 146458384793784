.navbar{
  width: 100%;
  min-height: 80px;
  padding: 0 10%;
  font-family: 'Roboto', sans-serif;
  z-index: 10;
}

.nav-top{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.navbar-brand{
  font-family: 'Comfortaa', cursive;
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-shadow: 0px 5px 3px black;
}

.nav-link{
  font-size: 18px;
  font-family: 'Comfortaa', cursive;
}

.nav-link:hover{
  color: #1c2c3b;
  border-bottom: 2px solid #1c2c3b;
}

.navbar-title{
  font-family: 'Comfortaa', cursive;
}

.line{
  height: 2px;
  background-color: black;
  width: 100px;
}

.position{
  position: absolute;
  top: 0;
  left: 0;
}

.title{
  /* margin-top: 70px; */
  text-align: center;
  /* padding-top: 50px; */
  /* background-color: black; */
  color: black;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid black;
}

@media only screen and (max-width: 700px){
  .line{
    width: 70px;
  }

  .navbar-title{
    font-size: 25px;
    margin-top: 26%;
  }
  .city-top-canvas{
    height: 250px;
    width: 100%;
  }
}

.title-home{
  background-color: rgb(0, 0, 0, 0.4);
}

.nav-link:hover{
  color: #1c2c3b!important;
}