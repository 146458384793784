@font-face{
  font-family: 'MyFont';
  src: url('../../assets/font/FUTRFW.TTF') format('truetype');
}

.home{
  background-color: black;
  font-family: 'MyFont';
  font-size: 20px;
}

.home_img{
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100vh;
  margin-top: 3%;
}

.fade_in{
  animation:fading 7s infinite;
}

.fade_in:hover{
  opacity: 1;
}

@keyframes fading{
  0%{opacity:0.2}
  50%{opacity:1}
  85%{opacity: 0.8}
  100%{opacity:0.2}
}

.home_text{
  width: 100%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 80%;
  left: 0;
  text-align: center;
  color: white;
  font-size: 30px;
  text-shadow: 2px 0px 5px rgb(34, 29, 29),
                 4px 0px 10px rgb(77, 74, 74);
}

.btns{
  width: 100%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 90%;
  left: 0;
  text-align: center;
  object-fit: scale-down;
}

.slide_btn{
  border: 0;
  height: 3px;
  width: 28px;
  margin: 0 10px;
  /* background-color: rgb(218, 215, 215, 0.5); */
  background-color: rgb(28, 44, 59, 0.5);
}

.slide_btn:hover{
  background-color: white;
}

.back_btn{
  position: absolute;
  height:100vh;
  width: 15vw;
  top: 0;
  left: 0;
  background: transparent;
  border: 0;
  color: rgb(255, 255, 255, 0.1);
  z-index: 2;
}

.next_btn{
  position: absolute;
  height:100vh;
  width: 15vw;
  top: 0;
  right: 0;
  background: transparent;
  border: 0;
  color: rgb(255, 255, 255, 0.1);
}

.rarrow{
  height: 50px;
  width: 80px;
}

.larrow{
  height: 50px;
  width: 80px;
  transform: rotate(180deg);
}

.slide_btn:focus, .back_btn:focus, .next_btn:focus{
  outline: none;
  border: none;
}

.fa-angle-left, .fa-angle-right{
  font-size: 80px;
}

::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b2b3c; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}