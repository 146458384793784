.project1_img{
  object-fit: cover;
  width: 60%;
}
.project_view{
  z-index: 999;
}

.thumbnail{
  width: 120px;
  height: 80px;
  transition: transform 0.7s;
}

.thumbnail-active{
  width: 120px;
  height: 80px;
  transform: translateY(-10px);
  transition: transform 0.7s;
}

.thumbnail:hover, .thumbnail-active:hover{
  transform: translateY(-30px);
}

.project-slide-btn-back, .project-slide-btn-next{
  background: transparent;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
  font-size: 175px;
  padding: 0 1%;
  position: relative;
  top: 40px;
}

.nextarrow{
  height: 60px;
}

.backarrow{
  height: 60px;
  transform: rotate(180deg);
}

.project-slide-btn-back:focus, .project-slide-btn-next:focus{
  border: 0;
  outline: 0;
}

.project-slide-btn-back:hover, .project-slide-btn-next:hover{
  color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 1366px){
  .project1_img{
    width: 80%;
  }

  .project-slide-btn-back, .project-slide-btn-next{
    font-size: 150px;
    position: relative;
    top:50px;
  }
}

@media screen and (max-width: 769px){
  .project-slide-btn-back, .project-slide-btn-next{
    font-size: 100px;
  }

  .project1_img{
    width: 80%;
    margin: 0 10%;
  }
}