.slick-arrow{
  color:red !important;
}
.slick-prev::before{
  background-color: black !important;

}
.contact{
  margin-top: 50px;
  margin-bottom: 50px;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
}

@media only screen and (min-width: 600px) {
  .contact_info{
    margin-top: 5vh;
  }
}

.form{
  background-color: rgb(0, 0, 0, 0.5);
  padding: 20px 20px;
  border-radius: 5px;
}

.Contact-head{
  color: #1c2c3b;
  font-size: 18px;
  font-family: 'Comfortaa', cursive;
}

.Contact-details{
  color : #708090;
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
}

.Contact-details a{
  color : #708090;
}

.form-button{
  width: 50%;
  margin-left: 25% ;
  background-color: rgb(0, 0, 0, 0.9);
  color: white;
  border: 0;
  height: 40px;
  border-radius: 5px;
}

@media only screen and (max-width: 700px){
  .form-button{
    width: 100%;
    margin: 0;
  }
}
.formcolour{
  background-color: white;
  color: orange;
}
.addressicon{
  height: 30px;
  width: 30px;
  color: #1c2c3b;
}
.emailicon{
  font-size: 25px;
  color: #1c2c3b;
}
.phoneicon{
  font-size: 25px;
  color : #1c2c3b;
}
/* Comment down this */
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255,255,255,0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06D85F;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06D85F;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06D85F;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px){
  .box{
    width: 70%;
  }
  .popup{
    width: 70%;
  }
}