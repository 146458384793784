
.SliderMainProperties{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 35px 0px;
  padding: 0;
  font-size: 3vmin;
  overflow: hidden;

}


.slides {
  display: grid;
  > .slide {
    grid-area: 1 / -1;
  }

  >button {
    appearance: none;
    background: transparent;
    border: none;
    color: black;
    position: absolute;
    font-size: 5rem;
    width: 5rem;
    height: 5rem;
    top: 85%;
    transition: opacity 0.3s;
    opacity: 0.7;
    z-index: 5;

    &:hover {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &:first-child {
      left: 1%;
    }
    &:last-child {
      right: 1%;
    }
  }
}


.slideContent {
  width: 70vw;
  height: 40vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  opacity: 0.7;

  display: grid;
  align-content: center;

  transform-style: preserve-3d;
  transform: perspective(800px) translateX(calc(100% * var(--offset)))
    rotateY(calc(-45deg * var(--dir)));
}
@media screen and (max-width: 769px){
  .SliderMainProperties{
    width: 110%;
  }
  .slideContent{
    transform: perspective(500px) translateX(calc(100% * var(--offset)))
    rotateY(calc(-35deg * var(--dir)));
  }

  .slides {
    display: grid;
    > .slide {
      grid-area: 1 / -1;
    }
  
    >button {
      appearance: none;
      background: transparent;
      border: none;
      color: black;
      position: absolute;
      font-size: 5rem;
      width: 5rem;
      height: 5rem;
      top: 65%;
      transition: opacity 0.3s;
      opacity: 0.7;
      z-index: 5;
  
      &:hover {
        opacity: 1;
      }
  
      &:focus {
        outline: none;
      }
  
      &:first-child {
        left: 1%;
      }
      &:last-child {
        right: 1%;
      }
    }
  }
}


.slide[data-active] {
  z-index: 2;
  pointer-events: auto;

  .slideBackground {
    opacity: 0.2;
    transform: none;
  }

  .slideContentInner {
    opacity: 1;
  }

  .slideContent {
    opacity: 1;

  }
}