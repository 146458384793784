.navbar-bottom{
  color: #1c2c3b;
  height: 70px;
  position: relative;
  border-top: 1px solid black;
  margin-bottom: 2%;
}

.nav-bottom{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.row{
  margin: 0;
}

.bottom-content{
  margin-top: 20px;
  margin-left: 5%;
}

.bottom-title{
  font-size: 25px;
  display: inline-block;
  padding-bottom: 5px;
  border-bottom: 3px solid white;
  font-family: 'Comfortaa', cursive;
}

.bottom-text{
  color: #12181d;
  font-size: 14px;
  font-family: 'Comfortaa', cursive;
  font-weight: 1000;
}

.bottom-text a{
  color: #12181d;
}

.fa-instagram{
  font-size: 30px;
  margin: 0px 0px 8px;
}
.fa-youtube{
  font-size: 30px;
  margin: 0px 0px 8px;
}

.insta{
  font-size: 20px;
}

.insta a{
  color: #1c2c3b;
  font-family: 'Comfortaa', cursive;
}

.fa-facebook-square{
  font-size: 30px;
  margin: 0px 0px 8px;
}
.facebook{
  font-size: 20px;
}

.facebook a{
  color: #1c2c3b;
  font-family: 'Comfortaa', cursive;
}