.linetag{
  height: 100vh;
  width: 100vw;
  background-color: #e5e5e5;
  position: absolute;
  top:0;
  left: 0;
  z-index: 99;
}
.load-logo{
  width: 200px;
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  animation: logo 2s infinite;
  z-index: 99;
}

@keyframes logo{
  0%{
    transform: scale(0.1) rotate(0deg);
  }
  33.34%{
    transform: scale(1) rotate(360deg);
  }
  66.67%{
    transform: scale(1) rotate(360deg);
  }
  100%{
    transform: scale(0.1) rotate(720deg);
  }
}

.line1{
  position: absolute;
  height: 0%;
  width: 50%;
  border: solid #1c2c3b 3px;
  background-color: #1c2c3b;
  transform: rotate(-5deg);
  left: 0%;
  top: 65%;
  animation: line1-in 2s infinite;
}

@keyframes line1-in{
  0%{
    top:65%;
    left:0%;
  }
  45%{
    top:65%;
    left:20%;
  }
  60%{
    top:65%;
    left:20%;
  }
  100%{
    top:65%;
    left:150%;
  }
}

.line2{
  position: absolute;
  height: 0%;
  width: 50%;
  border: solid #1c2c3b 3px;
  background-color: #1c2c3b;
  transform: rotate(20deg);
  left: 0%;
  top: 10%;
  animation: line2-in 2s infinite;
}

@keyframes line2-in{
  0%{
    top:10%;
    left:0%;
  }
  45%{
    top:35%;
    left:25%;
  }
  60%{
    top:35%;
    left:25%;
  }
  100%{
    top:80%;
    left:150%;
  }
}

.line3{
  position: absolute;
  height: 0%;
  width: 50%;
  border: solid #1c2c3b 3px;
  background-color: #1c2c3b;
  transform: rotate(-50deg);
  left: -5%;
  bottom: 15%;
  animation: line3-in 2s infinite;
}

@keyframes line3-in{
  0%{
    bottom:15%;
    left:-5%;
  }
  45%{
    bottom:45%;
    left:7%;
  }
  60%{
    bottom:45%;
    left:7%;
  }
  100%{
    bottom:150%;
    left:60%;
  }
}

.line4{
  position: absolute;
  height: 0%;
  width: 50%;
  border: solid #1c2c3b 3px;
  background-color: #1c2c3b;
  transform: rotate(-60deg);
  right: 0%;
  top: 15%;
  animation: line4-in 2s infinite;
}

@keyframes line4-in{
  0%{
    top:10%;
    right:0%;
  }
  45%{
    top:60%;
    right:15%;
  }
  60%{
    top:60%;
    right:15%;
  }
  100%{
    top:150%;
    right: 30%;
  }
}

@media screen and (max-width: 769px){
  .load-logo{
    width: 150px;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
  }

  .line1, .line2, .line3, .line4{
    width: 80%;
  }

  @keyframes line1-in{
    0%{
      top:65%;
      left:0%;
    }
    45%{
      top:65%;
      left:10%;
    }
    60%{
      top:65%;
      left:10%;
    }
    100%{
      top:65%;
      left:150%;
    }
  }

  @keyframes line2-in{
    0%{
      top:10%;
      left:0%;
    }
    45%{
      top:35%;
      left:20%;
    }
    60%{
      top:35%;
      left:20%;
    }
    100%{
      top:80%;
      left:150%;
    }
  }

  .line3{
    transform: rotate(-70deg);
  }

  @keyframes line3-in{
    0%{
      bottom:15%;
      left:-25%;
    }
    45%{
      bottom:50%;
      left:-10%;
    }
    60%{
      bottom:50%;
      left:-10%;
    }
    100%{
      bottom:150%;
      left:60%;
    }
  }

  @keyframes line4-in{
    0%{
      top:10%;
      right:-35%;
    }
    45%{
      top:60%;
      right:-15%;
    }
    60%{
      top:60%;
      right:-15%;
    }
    100%{
      top:150%;
      right: 30%;
    }
  }
}

@media screen and (max-width: 500px){
  @keyframes line1-in{
    0%{
      top:60%;
      left:0%;
    }
    45%{
      top:60%;
      left:10%;
    }
    60%{
      top:60%;
      left:10%;
    }
    100%{
      top:65%;
      left:150%;
    }
  }

  @keyframes line2-in{
    0%{
      top:10%;
      left:-10%;
    }
    45%{
      top:40%;
      left:20%;
    }
    60%{
      top:40%;
      left:20%;
    }
    100%{
      top:80%;
      left:150%;
    }
  }

  .line4{
    transform: rotate(-70deg);
  }

  @keyframes line4-in{
    0%{
      top:10%;
      right:-35%;
    }
    45%{
      top:50%;
      right:-15%;
    }
    60%{
      top:50%;
      right:-15%;
    }
    100%{
      top:150%;
      right: 30%;
    }
  }
}