.project{
  margin-top: 50px;
  margin-bottom: 50px;
}

.project_overlay{
  background: rgba(230, 230, 230, 0.8);
  position: absolute;
  margin: 0 15px;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.content:hover .project_overlay{
  opacity: 1;
}

.project_detail{
  position: absolute;
  /* background: rgba(0,0,0,0.5); */
  width: 70%;
  height: 70%;
  top:50%;
  left:15%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}

.project_text{
  color: white;
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  text-shadow: 0px 0px 2px black;
}

.project_text, .project_mobile{
  font-family: 'Comfortaa', cursive;
  font-size: 19px;
  color: #1c2c3b;
}

.project_overlay:hover .project_detail{
  opacity: 1;
}

.project_overlay:hover .project_text{
  top:50%;
}

.project_img, .col-md-2 > img{
  object-fit: cover;
  width: 100%;
  height: 200px;
}

.project_mobile{
  color: black;
}

a:hover{
  text-decoration: none;
}