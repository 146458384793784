.about-logo{
  height: 200px;
  width: 200px;
  color: red;
}

.about{
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 40vh;
}

.about-content{
  font-family: 'Roboto', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  text-align: justify;
  font-size: 17px;
}

.about-title{
  font-family: 'Crete Round', serif;
  font-size: 30px;
}

@media only screen and (max-width: 700px){
  .about-title{
    font-size: 25px;
  }
}

.col-md-6 > img, .col-lg-6 > img, .col-md-2 > img{
  object-fit: cover;
  width: 100%;
}