.card{
  margin-top: 5%;
  margin-bottom: 5%;
  height: 200px;
}
.fa-building{
  font-size: 700%;
  color: #1c2c3b;
}
.serviceimage{
  height: 100px;
}
.card:hover{
  transform: scale(1.1);
  transition-duration: 1.5s;
}
.card-title{
  font-family: 'Comfortaa', cursive;
}











/* .Service{
  margin-top: 50px;
  margin-bottom: 40vh;
}

.Service-about{
  font-family: 'Roboto', sans-serif;
  text-align: justify;
}

.Service-button{
    width: 350px;
    height: 40px;
    margin: auto;
    border: 0.1px solid black;
    background-color: rgb(192, 192 , 192);
    margin-top: 15px;
}
.Service-button:hover{
    background-color: green;
} */